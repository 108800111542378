/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* width hover */
/* body:hover::-webkit-scrollbar {
  width: 8px;
} */

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #898a8b;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #078fce57;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #078ece;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.15;
}

html {
  scroll-behavior: smooth;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  padding-right: 0px !important;
  background: #fff !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  background-size: cover;
  position: relative;
}

#root {
  min-height: 100%;
  position: relative;
}

#main {
  padding-bottom: 60px; /* Height of the footer */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
}

/* App Bar Css */
.app-bar {
  background-color: #fff !important;
  color: #000 !important;
}

.app-bar .brand_logo {
  height: 50px;
  width: 145px;
}

.app-bar .brand-logo-exam {
  height: 40px;
  width: 120px;
}

.app-bar .left,
.app-bar .institution-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-bar .left {
  width: 250px;
}

.app-bar .institution-name {
  max-width: 25vw;
}

/* Drawer Css */
#drawer {
  height: 100%;
  width: 240px;
  z-index: 1032;
  top: 0;
  left: -240px;
  transition: left 0.25s ease;
  background-color: #fff;
}

#drawer.active {
  left: 0;
}

#drawer-void {
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1031;
  background-color: rgba(0, 0, 0, 0.5);
}

#drawer .drawer-user {
  padding: 1.5rem 1rem 1rem;
  text-align: center;
  background-color: #078ece;
  border-bottom: 1px solid #078ece !important;
}

#drawer .drawer-user img {
  height: 83px;
  width: 64px;
  border: 1px solid rgb(228, 227, 227);
}

#drawer .nav-item a {
  text-decoration: none;
}

#drawer .nav-item.active {
  background-color: #e9ecef;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#drawer .nav-item .item-icon {
  min-width: 32px !important;
}

.fab-container {
  position: fixed;
  bottom: 0;
  right: 0;
}

.truncatable-text,
.breadcrumb-item.truncatable,
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncatable-text {
  width: 250px;
}

.breadcrumb-item.truncatable {
  max-width: 28vw;
}

.text-truncate {
  max-width: 230px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.elevated-0 {
  box-shadow: 0px 0px 18px -5px rgb(0 0 0 / 14%);
}

.elevated-1 {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.elevated-2 {
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);
}

.elevated-bottom-2 {
  box-shadow: 0px 16px 16px -5px rgb(0 0 0 / 30%);
}

a.hover-text-white:hover {
  color: white !important;
}

a button {
  text-decoration: none;
}

.expected-result-card:hover,
.indicator-card:hover {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.expected-result-card.not-assigned {
  border: 5px solid red !important;
}

.btn-link {
  text-decoration: none !important;
}

.btn-link:hover {
  color: blue !important;
}

.rounded-left-bottom {
  border-radius: 32px !important;
  border-top-left-radius: 0px !important;
}

.rounded-right-bottom {
  border-radius: 32px !important;
  border-bottom-right-radius: 0px !important;
}

.bread-crumb a.active,
nav a.active {
  color: yellow !important;
  text-decoration: underline !important;
}

.summary-card {
  color: #000;
  background-color: #fffaeb;
  text-transform: uppercase;
}

.summary-card .badge-dark {
  background-color: #6e7985 !important;
}

.menu-item {
  display: block;
  max-width: 24vw !important;
}

.role {
  display: block !important;
  max-width: 45vw !important;
}

.summary-card .d-block {
  font-size: 12px;
}

.loader {
  height: 100vh;
  position: relative;
}

.loader img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.roles span {
  font-size: 14px;
  display: flex;
  align-items: center;
  /* font-weight: bold; */
}

/* =================================== */

.tree-node {
  display: flex;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
}

.tree-node .tree-icon {
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-repeat: no-repeat;
}

.tree-node .tree-icon .open-icon {
  /* background-image: url('../../icons/plus.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.tree-node .tree-icon .close-icon {
  /* background-image: url('../../icons/minus.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.tree-node .tree-icon .empty-icon {
  /* background-image: url('../../icons/empty.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.node-content-wrapper {
  cursor: pointer;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  padding: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.2s ease;
}

.node-content-wrapper:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.node-content-wrapper.node-selected {
  border-left: 4px solid red;
}

.node-content-wrapper.titles {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.node-content-wrapper.node-title {
  font-weight: 600;
}

.node-content-wrapper.node-subtitle {
  margin-top: 4px;
  font-size: 10px;
}

.children-length {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 16px;
  background-color: red;
  color: white;
  padding: 4px;
  font-size: 12px;
  border-radius: 16px;
  margin-left: 12px;
  font-weight: 600;
}

.tree-wrapper {
  width: 50%;
  padding: 0 0 0 16px;
}

.tree-wrapper.simple {
  overflow: auto;
}

.modules-menu {
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
}

.modules-menu button,
.modules-menu a {
  /* border: 1px solid #088dce; */
  color: #065c84;
  font-size: 0.75rem;
  border-radius: 0px !important;
  margin-right: 10px !important;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  /* border: 1px solid #088dce; */
}

.modules-menu button.active,
.modules-menu a.active {
  color: #fff !important;
  background-color: #065c84;
}

.modules-menu button.active:hover,
.modules-menu a.active:hover {
  color: #fffb00 !important;
}

.modules-menu button.active img.invert {
  filter: invert(298%) sepia(152%) saturate(659%) hue-rotate(300deg)
    brightness(94%) contrast(245%);
}

.MuiTreeItem-content {
  /* padding-top: 1px !important;
  padding-bottom: 1px !important; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiTreeItem-content .add-padding {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.pagination .btn:hover {
  background-color: #add1f7 !important;
  color: #000 !important;
}

.pagination .active {
  background-color: #007bff !important;
}

.pagination .active:hover {
  background-color: #007bff !important;
  color: #fff !important;
}

.elevated {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.form-control {
  border-width: 1px !important;
  border-color: #17a2b8;
}
.form-control:focus {
  border-color: #2566e8 !important;
  box-shadow: none !important;
  border-width: 2px !important;

  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0mv !important;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 4px !important;
  /* box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)!important;; */
}

.searchIcon {
  position: absolute;
  top: 8px;
  right: 24px;
  color: #17a2b8;
}
.loaderIcon {
  position: absolute;
  top: 8px;
  right: 24px;
  color: #17a2b8;
  width: 24px !important;
}
.w-100 {
  width: 100%;
}
.h-50 {
  height: 60px;
}

.exam__dashboard {
  width: 100%;
}
.exam__dashboard .top_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0px 20px; */
  flex-wrap: wrap;
}
.exam__dashboard .top_cont .card_item {
  background-color: #fff;
  display: flex;
  justify-self: start;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  max-width: 280px;
  flex: 1;
  border: 1px solid var(--maincl-lght-l);

  box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
  -moz-box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
}
.exam__dashboard .top_cont .card_item .img_icon {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
}
.exam__dashboard .top_cont .card_item .img_icon img {
  position: absolute;
  width: 75%;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.exam__dashboard .top_cont .details_con h2 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--maincl-lght);
}
.exam__dashboard .top_cont .details_con h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--fnheadingcl);
  font-family: "Overpass", sans-serif;
}
thead th {
  border-bottom-width: 1px !important;
}

.tablex thead th {
  vertical-align: bottom;
  border-bottom: thin solid #dee2e6 !important;
  /* text-align: right; */
}
.tablex td {
  width: 130px;
  padding: 0.3rem;
  /* text-align: right; */
}

.align-level-centered {
  text-align: center !important;
}

.align-indexvalue-right {
  text-align: right !important;
}

.tablex td input {
  position: relative;
  padding: 0.3rem;
  /* vertical-align: ; */
  border: 1px solid rgb(7, 142, 206);
  width: 100%;
  text-align: right !important;
}

.tablex td input[type="checkbox"] {
  transform: scale(1.5);
  padding: 0.3rem;
  margin-top: 8px;
  z-index: 0 !important;
}

/* td.span {
  table-layout: fixed;
  width: 500px!important;
} */

.hovered-td {
  background-color: #e9ecef !important;
}
.hovered-td-box {
  padding: 0 !important;
  background-color: #e9ecef !important;
}
.hovered-row {
  background-color: #e9ecef !important;
}

.tablejc td,
.table th {
  padding: 0.75rem;
  /* vertical-align: middle !important; */
}

.span {
  display: inline-block;
  width: 300px !important;
}

.structure-unit-drawer {
  width: 450px;
  /* background-color: hsl(0deg 0% 0% / 4%);
  height: 50vh; */
}

.fixTableHead {
  overflow-y: auto;
  height: 110px;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
  z-index: 1 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 15px;
}
th {
  background: #e9ecef;
}

.table-striped {
  font-size: 11px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.table-striped tr:nth-child(even) {
  background-color: #f3f6f8 !important;
}

.table-striped tr:nth-child(odd) {
  background-color: #fff !important;
}

td {
  vertical-align: middle !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

.css-1auycx3-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 4px !important;
}

.user-contact {
  padding: 10px 0 20px;
  border-bottom: 1px solid #a4b0bc;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user-contact-2 {
  flex: 1;
  padding-left: 16px;
}

.user-contact-2 h3 {
  font-size: 0.85rem;
  font-weight: 400;
  color: #088dce;
  line-height: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.user-contact-3 {
  width: 100%;
}
.user-contact-3 h3 {
  font-size: 0.85rem;
  font-weight: 400;
  color: #088dce;
  line-height: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.user_profile_detail {
  display: block;
  font-size: 0.85rem;
  font-weight: 400;
  color: #344457;
  line-height: 1.8rem;
}

.contact_p {
  font-size: 0.85rem;
  font-weight: 400;
  color: #a4b0bc;
  margin-bottom: 8px;
}
.contact_span {
  font-weight: 600;
  color: #344457;
  font-size: 0.85rem;
}

.card {
  /* border: 1px solid #eff2f5; */
  /* box-shadow: #eff2f5;
  background-color: #ffffff; */
}

.bg-light-success {
  background-color: #eefbec !important;
}
.text-gray-800 {
  color: #28346c !important;
}
.z-index-2 {
  z-index: 2 !important;
}
.fw-bold {
  font-weight: 600 !important;
}
.fs-1 {
  font-size: calc(1.1rem + 0.4vw) !important;
}

.text-gray-600 {
  color: #7e8299 !important;
}
.fw-semibold {
  font-weight: 500 !important;
}
.fs-6 {
  font-size: 1.075rem !important;
}

.mb-7 {
  margin-bottom: 1rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}
.h-175px {
  height: 175px !important;
}

.position-relative {
  position: relative !important;
}

@media (min-width: 768px) {
  .me-md-0 {
    margin-right: 0 !important;
  }
}
.me-9 {
  margin-right: 2.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.border-gray-300 {
  border-color: #e4e6ef !important;
}
.min-w-80px {
  min-width: 100px !important;
}
.rounded {
  border-radius: 0.85rem !important;
}

.rounded-top {
  border-top-left-radius: 0.85rem !important;
  border-top-right-radius: 0.85rem !important;

  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.text-gray-700 {
  color: #596394 !important;
}

.text-gray-400 {
  color: #078ece !important;
}

strong.employee-name {
  display: inline-block;
}

strong.employee-name:first-letter {
  text-transform: uppercase;
}

.border-dashed {
  border-style: solid !important;
  border-color: #078ece;
}
.card-header {
  /* padding: 0.75rem 0.75rem!important;  */
}
.card-title {
  margin-bottom: 0 !important;
}
.notify--info {
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.notify--info::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  border: solid 1px;
  width: 4px;
  height: 100%;
  border-radius: 8px 0 0 8px;

  background: rgb(7, 142, 206);
  border-color: rgb(7, 142, 206);
}
.notify__icon-holder {
  padding: 16px 0 0 16px;
}

.notify__content-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  padding: 16px 0 0 8px;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.notify__content {
  font-family: DineroSans, sans-serif;
  color: #1c252c;
  font-weight: normal;
  -webkit-flex: 1;
  flex: 1;
  margin: 0 24px 16px 0;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.text--link {
  text-decoration: none;
  color: rgb(7, 142, 206);
  font-weight: bold;
  cursor: pointer;
}

/* custom css */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.search-input {
  border-radius: 75;
  /* border:1px; */
  width: 100%;
  height: 2.475rem;
  padding-left: 2.376rem;
}

.search-input:focus {
  border: 2px;
  outline-style: double;
  outline-color: #078ece;
}

.bg-blue-hover:hover {
  background-color: #078ece;
}

.btn-blue-light {
  background-color: #faf8f6;
  color: #078ece;
  border: 0;
}
.btn-blue-light:hover {
  background-color: #76bafe;
  color: #fff;
}

.bg-blue-light-hover:hover,
.bg-table-blue {
  background-color: #f6f8fa;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:focus {
  border: 1px solid black;
}
.text-underline {
  text-decoration: underline !important;
}

.cursor-loading {
  cursor: wait;
}

.text-blue {
  color: #078ece;
}

.text-blue-100 {
  color: #0575aa;
}

.drawer-btn:hover {
  background: #abd3fa;
}

.bg-blue-light {
  background-color: #fdfdfd;
}

.btn-link-sm {
  cursor: pointer;
  color: #067cb2;
  /* font-size: small; */
}

.btn-link-sm:hover {
  text-decoration: underline;
}

.custom-card {
  border: #dee2e6 1px solid;
  border-left: #dee2e6 4px solid;
  border-radius: 1px;
}

.custom-card:hover {
  border: #007bff 1px solid;
  border-left: #007bff 4px solid;
  border-radius: 2px;
}

.custom-card-active {
  border: #007bff 1px solid;
  border-left: #007bff 4px solid;
  /* border-radius: 2px; */
}

.body-max-height {
  max-height: 50vh;
}

.overflow-x-auto {
  overflow-x: auto;
}

.form-min-w {
  min-width: 300px;
}

.language-card {
  border-left: #aaa 4px solid;
}

.language-card:hover {
  border-left: #18b4ec 4px solid;
  background-color: #f9f9f9;
}

.btn-icon {
  border: none;
  background: none;
  padding-bottom: 2px;
  border-radius: 4px;
}

.btn-icon:hover {
  background: #f6f8fa;
}

.text-icon {
  border: none;
  background: none;
  padding-bottom: 2px;
  border-radius: 4px;
}

.text-icon:hover {
  color: #078ece;
}

.btn-text-icon {
  display: block;
  border: none;
  border-radius: 10%;
  color: #078ece;
  text-align: center;
  background: #f6f8fa;
  box-shadow: 0 0 3px gray;
  font-size: 13px;
  font-weight: 600;
}

.btn-text-icon:hover {
  border: 2px solid #f5f5f5;
  background: #078ece;
  color: #f6f8fa;
}

.parent-hover:hover {
  background-color: #f6f8fa;
}

.parent-hover:hover > .btn-icon {
  background-color: #067cb2;
  color: #fdfdfd !important;
}

.parent-hover:hover > .text-icon {
  color: #078ece;
}

.-mt-8 {
  margin-top: -8px;
}

.max-w-content {
  max-width: fit-content;
}

.tr-hover:hover {
  border: 2px solid #067cb2;
}

.tr-bordered-blue {
  border: 2px solid #067cb2;
}

.tr-bg-green td {
  background-color: #6cd76c68 !important;
  font-weight: bold;
}

.tr-bg-blue td {
  background-color: #6c87d768 !important;
  font-weight: bold;
}

.tr-bg-red td {
  background-color: #ff01011f !important;
}

.bg-blue {
  background-color: #078ece;
  color: #faf8f6;
}

.text-warning {
  color: #dca500 !important;
}

/* application notifications */

.status-bg-default {
  background-color: #828581;
  color: #f0fff0;
  font-weight: 500;
  min-height: 100%;
}

.border-default {
  background-color: #fff;
  border-left: 1px solid #828581;
  border-right: 1px solid #828581;
  font-size: small;
  max-width: fit-content;
  min-height: 100%;
  color: #333333;
}

.status-bg-fail {
  background-color: #990000;
  color: #f0fff0;
  font-weight: 500;
  min-height: 100%;
}

.border-fail-application {
  background-color: #fff;
  border-left: 1px solid #990000;
  border-right: 1px solid #990000;
  max-width: fit-content;
  min-height: 100%;
  color: #011a01;
}

.border-fail {
  background-color: #fff;
  border-left: 1px solid #990000;
  border-right: 1px solid #990000;
  max-width: fit-content;
  min-height: 100%;
  color: #990000;
}

.status-bg-success {
  background-color: #046004;
  color: #f0fff0;
  font-weight: 500;
  min-height: 100%;
  font-size: small;
}

/* .border-success-application {
  background-color: #fff;
  border-left: 1px solid #046004;
  border-right: 1px solid #046004;
  max-width: fit-content;
  min-height: 100%;
  color: #011a01;
} */

/* .border-success {
  background-color: #fff;
  border-left: 1px solid #046004;
  border-right: 1px solid #046004;
  max-width: fit-content;
  min-height: 100%;
  color: #006600;
} */

.text-small {
  margin-top: -2px;
  font-size: x-small;
  font-weight: 700;
}

.file-section {
  border: 2px solid #adacac;
  border-radius: 4px;
  height: 100%;
  cursor: pointer;
}

.file-section:hover {
  background-color: #f4f4f4;
}

.transition-2s {
  transition-duration: 4s;
}

.exam-box {
  box-shadow: 0 5px 5px 0 rgb(42 119 144 / 5%);
  -moz-box-shadow: 0 5px 5px 0 rgba(42, 119, 144, 0.05);
  -webkit-box-shadow: 0 5px 5px 0 rgb(42 119 144 / 5%);
}

.exam-answer {
  background: #f6f8faa3;
}

.exam-answer:hover {
  background: #f6f8fa;
}

.disable-text-selection {
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 3px !important;
  font-size: 11px !important;
}

.rotated {
  transform: rotate(90deg);
  font-weight: 100 !important;
  color: #87d4d4;
}

.ql-editor {
  min-height: 160px;
}

/* bread-crumb stepper */
.breadcrumb-stepper {
  display: inline-block;
  overflow: hidden;
  border-radius: 3px;
  counter-reset: flag;
}

.breadcrumb-stepper a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 30px;
  color: #078ece;
  border: 1px solid #078ece;
  /*need more margin on the left of links to accommodate the numbers*/
  padding: 0 10px 0 30px;
  background: #f6f8fa;
  position: relative;
}

/* add arrow on children */
.breadcrumb-stepper a:after {
  content: "";
  position: absolute;
  top: 0;
  right: -15px; /*half of square's length*/
  width: 30px;
  height: 30px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  background: #f6f8fa;
  box-shadow: 2px -2px 0 2px #078ece, 3px -3px 0 2px #078ece;
  border-radius: 0 15px 0 50px;
}

.breadcrumb-stepper a:last-child:after {
  content: none;
}

.breadcrumb-stepper a:first-child {
  padding-left: 20px;
  border-radius: 5px 0 0 5px; /*to match with the parent's radius*/
}
.breadcrumb-stepper a:first-child:before {
  left: 14px;
}
.breadcrumb-stepper a:last-child {
  border-radius: 0 5px 5px 0; /*this was to prevent glitches on hover*/
  padding-right: 20px;
}

.breadcrumb-stepper a.active,
.breadcrumb-stepper a:hover {
  background: #078ece;
  color: #f6f8fa;
}
.breadcrumb-stepper a.active:after,
.breadcrumb-stepper a:hover:after {
  background: #078ece;
  color: #f6f8fa;
}

.text-truncate-300 {
  max-width: 300px;
}

.bg-gray-hover:hover {
  background: #28a74517;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
}

.page-loading-void {
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1031;
  /* margin-left: -15px; */
  margin-left: -43px;
  background-color: rgba(0, 0, 0, 0.08);
}

.text-black {
  color: #000 !important;
}

.bg-primary {
  background-color: #078ece !important;
}

.loader2 {
  -webkit-animation: spin 1s linear infinite !important; /* Safari */
  animation: spin 1s linear infinite !important;
}

.list-group-item.list-group-item-action.active {
  background-color: #078ece !important;
  border: 1px solid #078ece !important;
  color: #fff !important;
}

.td-max-width {
  /* width: 54px !important; */
  white-space: nowrap;
}

.calculator {
  /* makes it's width fit the content */
  display: inline-block;
}
.calculator button {
  width: 25%;
  height: 25%;
  border-radius: 0px;
  /* margin: 5px 10px; */
  font-weight: bold;
  font-size: 17;
}
.calculator .input-group {
  width: 100%;
  display: inline-block;
}
.calculator input[type="text"] {
  border-radius: 0px;
  height: 46px;
  display: inline-block;
  width: 100%;
  /* margin: 0px 10px; */
}

.question-option:hover {
  background-color: #a9d5fe4c !important;
  border: 1px solid rgba(0, 122, 128, 0.463) !important;
}

.question-option.selected {
  background-color: #adfea9ac;
  border: 1px solid green !important;
  /* font-weight: bold; */
  color: blue;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.bg-primary2 {
  background-color: #054d6f !important;
}
.text-blue-main {
  color: #065c84 !important;
}

.text-success {
  color: #175e28 !important;
}

.text-primary {
  color: #0054ad !important;
}

.text-secondary {
  color: #53595f !important;
}

.text-warning {
  color: #8f6b00 !important;
}

.badge-success {
  background-color: #175e28 !important;
}

.badge-primary {
  background-color: #0054ad !important;
}

.btn-link {
  color: #0054ad !important;
}

@media (min-width: 450px) {
  #chat-widget {
    font-size: 14px;
  }
}
.chat-widget {
  position: fixed;
  z-index: 999999999999;
  opacity: 0;
  animation-delay: 0.15s;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: kdaddy-fadeIn;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
.chat-widget,
.chat-widget1 {
  background-color: inherit;
  font-family: Circular, Circular Std, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

.chat-button,
.chat-button1 {
  z-index: 99992147483000;
  position: fixed;
  bottom: 20px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  border-radius: 50%;
  transition: opacity 0.3s ease 0s;
}

.chat-button-label,
.chat-button-label1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #1e2433;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 15px;
  white-space: nowrap;
  margin: 0 15px;
  font-size: 14px;
  line-height: 1.2;
  box-shadow: 0 6px 32px #00122e3d;
  transition: margin 0.16s linear 0s, opacity 80ms linear 0s;
}

.chat-button,
.chat-button1 {
  color: #fff;
  background: #054d6f !important;
  box-shadow: #1e2433;
}

.chat-button .svg-whatsapp,
.chat-button1 .svg-whatsapp {
  width: 32px;
  height: 32px;
  fill: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  opacity: 1;
  transform: rotate(0) scale(1);
  transition: transform 0.16s linear 0s, opacity 80ms linear 0s;
}

.chat-button .svg-close,
.chat-button1 .svg-close,
.svg-close {
  width: 14px;
  height: 14px;
  fill: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -7px;
  margin-left: -7px;
  opacity: 0;
  transform: rotate(-90deg);
  transition: transform 0.16s linear 0s, opacity 80ms linear 0s;
}

.support-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.support-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 20px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.chatbox-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chatbox-header {
  background-color: rgb(7, 142, 206);
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbox-content {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

.chatbox-footer {
  padding: 10px;
  background-color: #f1f1f1;
  display: flex;
  gap: 10px;
}

.chatbox-footer input {
  flex-grow: 1;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chatbox-footer button {
  background-color: rgb(7, 142, 206);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.footer-icon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  vertical-align: middle;
  margin-right: 2px;
  margin-top: -1px;
}
